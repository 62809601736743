
export function isTextColorDark(hex) {
	let hexColor = hex.replace('#', '');
	let r = parseInt(hexColor.substring(0, 2), 16);
	let g = parseInt(hexColor.substring(2, 4), 16);
	let b = parseInt(hexColor.substring(4, 6), 16);
	let yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq > 160;
}


export function searchMainColors(file, callback) {

	let img = document.createElement('img');
	img.style.display = 'none';
	img.src = file;
	document.body.appendChild(img);

	img.onload = function() {

		let canvas = document.createElement("canvas");
		let ctx = canvas.getContext("2d");
		let colors = [], colorChoosen = false;

		canvas.style.left = "0";
		canvas.style.top = "0";
		canvas.width = Math.min(img.width, 1080);
		canvas.height = Math.min(img.height, 1080);
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
		let imgInfo = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

		for (let i = 0; i < imgInfo.length; i += 4) {
			colors.push({"red": imgInfo[i], "green": imgInfo[i + 1], "blue": imgInfo[i + 2]});
		}

		let mainColors = kmeans(colors, 3, 1);
		mainColors = mainColors ? mainColors : [];
		mainColors = mainColors.sort((a, b) => {
			return b.qty - a.qty
		});

		for (let i = 0; i < mainColors.length; i++) {
			if (!colorChoosen) {
				colorChoosen = convertRgbToHex(mainColors[i]);
				break;
			}
		}
		if (!colorChoosen) {
			colorChoosen = "#0752A3";
		}
		callback(colorChoosen);

	};


}

export function convertRgbToHex(rgbColor) {
	let red, green, blue;

	red = (rgbColor.red).toString(16);
	red = red.length === 1 ? '0' + red : red;
	green = (rgbColor.green).toString(16);
	green = green.length === 1 ? '0' + green : green;
	blue = (rgbColor.blue).toString(16);
	blue = blue.length === 1 ? '0' + blue : blue;

	return '#' + red + green + blue;
}

function kmeans(colors, k, min_distance) {
	let clusters = [];

	for (let i = 0; i < k; i++) {
		clusters.push(selectInitialCentroids(colors, k, i));
	}

	let colorsGroupedByClusters = [], breaksLoop = [];
	let index = 0;

	colors.forEach((color) => {
		let distance = 10000000, newDistance = 0;
		clusters.forEach((cluster, key) => {
			newDistance = euclideanDistance(color, cluster);
			if (newDistance < distance) {
				distance = newDistance;
				index = key;
			}
		})
		if (!colorsGroupedByClusters[index]) {
			colorsGroupedByClusters[index] = [];
		}
		colorsGroupedByClusters[index].push(color);
	});

	clusters.forEach((cluster, cKey) => {
		let oldCluster = cluster, distanceCentroids = 0;
		let newCluster = calculateCentroid(colorsGroupedByClusters[cKey]);

		if (newCluster) {
			distanceCentroids = euclideanDistance(oldCluster, newCluster);
			clusters[cKey] = newCluster;
			clusters[cKey].qty = colorsGroupedByClusters[cKey].length;

			if (distanceCentroids < min_distance) {
				breaksLoop[cKey] = true;
			}
		}
	});

	return clusters
}

function selectInitialCentroids(colors, k, i) {
	let coordX = 0, coordY = 0, index = 0, width = 120, height = 120;
	i = i + 1;
	let segX = (k % 3 === 0 ? Math.floor(width / 4) : Math.floor(width / ((i % 3) + 1)));
	let segY = (k % 3 === 0 ? Math.floor(Math.floor(height / ((k / 3)) + 1)) : height / (Math.floor(k / 3) + 2))
	coordX = (i % 3 === 0 ? segX * 3 : segX * (Math.floor((i % 3))))
	coordY = (i % 3 === 0 ? segY * (Math.floor((i / 3))) : segY * (Math.floor((i / 3)) + 1))
	index = (coordX - 1) * (coordY - 1)

	return colors[index];


}

function euclideanDistance(color, cluster) {
	return Math.sqrt(Math.pow(color.red - cluster.red, 2) + Math.pow(color.green - cluster.green, 2) + Math.pow(color.blue - cluster.blue, 2));
}

function calculateCentroid(colorsByCluster) {
	let red = 0, green = 0, blue = 0, divider = colorsByCluster ? colorsByCluster.length : 0;
	if(colorsByCluster) {
		colorsByCluster.forEach((color) => {
			red += color.red;
			green += color.green;
			blue += color.blue;
		});
	} else {
		return false;
	}

	return {
		"red": Math.floor((red / divider)),
		"green": Math.floor((green / divider)),
		"blue": Math.floor((blue / divider))
	};

}

export function hexToRgb(hex) {
	hex.replace('#', '');
	let r = parseInt(hex.slice(0, 2), 16),
		g = parseInt(hex.slice(2, 4), 16),
		b = parseInt(hex.slice(4, 6), 16);
	return [r, g, b];
}

function luminance(r, g, b) {
	let a = [r, g, b].map((v) => {
		v /= 255;
		return v <= 0.03928 ?
			v / 12.92 :
			Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrastRatio(hex1, hex2) {
	let rgb1 = hexToRgb(hex1),
		rgb2 = hexToRgb(hex2),
		lum1 = luminance(...rgb1),
		lum2 = luminance(...rgb2),
		ratio = (lum1 + 0.05) / (lum2 + 0.05);

	if (lum1 < lum2) {
		ratio = 1 / ratio;
	}

	return ratio;
}

export function isContrastEnoughForText(hex1, hex2, isLargeText=false) {
	let contrast = contrastRatio(hex1, hex2);
	if (isLargeText) {
		return contrast >= 2;
	} else {
		return contrast >= 2.5;
	}
}

export function adjustColorForContrast(alpha, beta, isLargeText=false) {
	let accessible = isContrastEnoughForText(alpha, beta, isLargeText);
	if (accessible) {
		return alpha;
	}

	let [rD, gD, bD] = hexToRgb(alpha);
	let [rL, gL, bL] = hexToRgb(alpha);
	let maxIterations = 100;
	let i = 0;
	while (!accessible) {
		// darken the color
		rD = Math.max(0, rD - 5);
		gD = Math.max(0, gD - 5);
		bD = Math.max(0, bD - 5);

		// Convert RGB back to hex
		const newAlphaDarker = [rD, gD, bD].map(x => x.toString(16).padStart(2, "0")).join("");
		accessible = isContrastEnoughForText(newAlphaDarker, beta, isLargeText);
		if (accessible) {
			return newAlphaDarker;
		}

		// lighten the color
		rL = Math.min(255, rL + 5);
		gL = Math.min(255, gL + 5);
		bL = Math.min(255, bL + 5);

		// Convert RGB back to hex
		const newAlphaLighter = [rL, gL, bL].map(x => x.toString(16).padStart(2, "0")).join("");
		accessible = isContrastEnoughForText(newAlphaLighter, beta, isLargeText);
		if (accessible) {
			console.log("Lighter color found", newAlphaLighter, "iterations", i);
			return newAlphaLighter;
		}

		i++;
		if (i > maxIterations) {
			console.warn("max iterations reached for color adjustment");
			return alpha;
		}
	}
}